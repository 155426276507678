import React from 'react';
import { Container, Box, Typography, Button, Avatar, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

// Image imports (update paths as necessary)
import profileImage from '../src/assets/3.png';
import vipImage from '../src/assets/2.png';
import instagramImage from '../src/assets/1.png';
import tiktokImage from '../src/assets/4.png';

function App() {
  return (
    <Box sx={{ minHeight: '100vh', position: 'relative' }}>
      {/* Background with dark overlay */}
      <Box
        sx={{
          backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/profile-mgmt.appspot.com/o/images%2Fbackground.jpg?alt=media&token=7731aae8-5144-46ff-975b-f24da757975e)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'fixed', // Make sure the background is fixed and covers full viewport
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      >
        {/* Dark overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Darken the background with 50% opacity
            zIndex: 1,
          }}
        />
      </Box>

      {/* Main content in a container */}
      <Container maxWidth="sm" sx={{ zIndex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '100vh', mb: 10 }}>
        
        {/* Profile Section */}
        <Box sx={{ mb: 4, mt: 4 }}>
          <Avatar  src={profileImage} alt="Profile" sx={{ width: 175, height: 175, margin: 'auto' }} />
          <Typography variant="h3" sx={{ color: 'white', fontWeight: 'bold', mt: 4, fontSize: 28 }}>LEAH MIFSUD</Typography>
          <Typography variant="subtitle1" sx={{ color: 'white' }}>DIGITAL CREATOR</Typography>
        </Box>

        {/* Links Section */}
        <Box sx={{ mb: 6, width: '100%' }}>
          <Button href="https://onlyfans.com/leah_mifsud" target='_blank' variant="contained" fullWidth sx={{ mb: 2, justifyContent: 'flex-start', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)', p: 1.5}}>
            <Avatar variant='rounded' src={vipImage} alt="VIP content" sx={{ mr: 2, width: 75, height: 75 }} />
            The VIP content you are looking for
          </Button>

          <Button href="https://www.instagram.com/leah_mifsud/" target='_blank' variant="contained" fullWidth sx={{ mb: 2, justifyContent: 'flex-start', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)', p: 1.5 }}>
            <Avatar variant='rounded' src={instagramImage} alt="Instagram" sx={{ mr: 2, width: 75, height: 75 }} />
            Check out my Instagram Profile
          </Button>

          <Button href="https://www.tiktok.com/@leah_mifsud/" target='_blank' variant="contained" fullWidth sx={{ mb: 2, justifyContent: 'flex-start', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)', p: 1.5 }}>
            <Avatar variant='rounded' src={tiktokImage} alt="TikTok" sx={{ mr: 2, width: 75, height: 75 }} />
            Check out my latest TikTok videos
          </Button>

          <Button href="https://twitter.com/horsequeenleah" variant="contained" target='_blank' fullWidth sx={{ mb: 2, justifyContent: 'flex-start', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)', p: 1.5 }}>
            Follow me on Twitter
          </Button>

          <Button href="https://www.facebook.com/profile.php?id=61556234583275#" target='_blank' variant="contained" fullWidth sx={{ justifyContent: 'flex-start', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)', p: 1.5 }}>
            Follow me on Facebook
          </Button>
        </Box>

        {/* Footer with Social Icons */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton href="https://www.instagram.com/leah_mifsud/" target='_blank' color="inherit" sx={{ color: 'white' }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://twitter.com/horsequeenleah" target='_blank' color="inherit" sx={{ color: 'white' }}>
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://www.facebook.com/profile.php?id=61556234583275#" target='_blank' color="inherit" sx={{ color: 'white' }}>
            <FacebookIcon />
          </IconButton>
        </Box>

        <Typography variant="body2" sx={{ color: 'white', mt: 2 }}>
          FOLLOW ME ON SOCIAL MEDIA
        </Typography>
      </Container>
    </Box>
  );
}

export default App;
